<template>
  <div>
    <!--begin::Row-->
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">Daftar PTSP Pemda Kabupaten / Kota</h3>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0">
            <!--begin::Item-->
            <div class="mb-12">
              <!--begin::Content-->
              <div>
                <div style="overflow-x: auto; overflow-y: hidden">
                  <table
                    id="example"
                    class="table table-striped table-bordered"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th style="width: 350px">Nama</th>
                        <th>Nilai Kriteria Kinerja Pelayanan</th>
                        <th>Nilai Kriteria PPB</th>
                        <th>Nilai Total</th>
                        <th>Aksi</th>
                        <th>Hasil Penilaian Mandiri</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="row in kabkota" :key="row.id">
                        <td></td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.total_1 }}</td>
                        <td>{{ row.total_2 }}</td>
                        <td>{{ row.total_skor }}</td>
                        <td>
                          <div
                            v-if="row.total_1 == null && row.total_2 == null"
                          >
                            <b-button
                              v-if="status.status_sistem_pk === 'Buka'"
                              size="sm"
                              @click="getidkabkota(row.id)"
                              variant="primary"
                              >Mulai Penilaian</b-button
                            >
                          </div>
                          <div v-else>
                            <!-- <div v-if="status.status_sistem === 'Buka'"> -->
                            <b-button
                              size="sm"
                              @click="getidpenilaian(row.id)"
                              variant="success"
                              ><i class="flaticon-eye"></i>Lihat
                              Penilaian</b-button
                            >
                            <!-- </div> -->
                          </div>
                        </td>
                        <td>
                          <div style="width: 250px">
                            <b-button
                              size="sm"
                              @click="geturl(row.id)"
                              variant="success"
                              v-b-tooltip.hover
                              title="Lihat detail penilaian"
                              ><i class="flaticon-eye"></i>Lihat PTSP</b-button
                            >
                            <b-button
                              size="sm"
                              class="ml-10"
                              @click="geturlppb(row.id)"
                              variant="success"
                              v-b-tooltip.hover
                              title="Lihat detail penilaian"
                              ><i class="flaticon-eye"></i>Lihat PPB</b-button
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--end::Content-->
            </div>
            <!--end::Item-->
          </div>
          <!--end: Card Body-->
        </div>

        <div
          class="modal fade"
          id="exampleModalScrollable"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Persetujuan
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Setuju</label>
                  <div class="col-lg-6">
                    <b-form-radio
                      id="setuju"
                      v-model="persetujuan"
                      name="some-radios"
                      value="Ya"
                      >Ya</b-form-radio
                    >
                    <b-form-radio
                      id="tidaksetuju"
                      v-model="persetujuan"
                      name="some-radios"
                      value="Tidak"
                      >Tidak</b-form-radio
                    >
                  </div>
                </div>
                <div id="alasan" style="display: none" class="form-group row">
                  <label class="col-lg-4 col-form-label"
                    >Alasan /
                    <p style="font-style: italic">Disclaimer</p></label
                  >
                  <div class="col-lg-6">
                    <textarea
                      type="text"
                      v-model="alasan"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Tutup
                </button>
                <button
                  type="submit"
                  @click="submitpersetujuan"
                  style="background-color: #030a8c; border-color: #030a8c"
                  data-dismiss="modal"
                  class="btn btn-primary"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import VueLoading from "vuejs-loading-plugin";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  name: "lstprovinsippk",
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      idpenilaian: "",
      user: JSON.parse(localStorage.getItem("user")),
      tanggal: localStorage.getItem("tanggal"),
      idptsp_kabkota: "",
      tahun: new Date().getFullYear(),
      setuju: [
        { text: "Ya", value: "YA" },
        { text: "Tidak", value: "TIDAK" },
      ],
      persetujuan: "Ya",
      test: "hai",
      // persetujuan: '',
      pm: [],
      kabkota: [],
      alasan: "",
      search: "",
      status: [],
      headers: [
        {
          text: "Nama",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Nilai Kriteria Kelembagaan ", value: "total_1" },
        { text: "Nilai Kriteria Kinerja", value: "total_2" },
        {
          text: "Nilai Kinerja Mendorong Kegiatan Sosial dan Lingkungan",
          value: "total_3",
        },
        { text: "Aksi", value: "" },
        { text: "Penilaian Mandiri PTSP Pemda ", value: "" },
      ],
      pm: [],
    };
  },
  components: {
    vSelect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Penilaian Pemangku Kepentingan oleh PTSP Provinsi" },
    ]);
    this.getkegiatan();
    this.getkabkota();

    $(document).ready(function () {
      $("#setuju").click(function () {
        if ($(this).is(":checked")) {
          $("#alasan").css("display", "none");
        } else {
          $("#alasan").css("display", "");
        }
      });
      $("#tidaksetuju").click(function () {
        if ($(this).is(":checked")) {
          console.log("tidaksetuju");
          $("#alasan").css("display", "");
        } else {
          $("#alasan").css("display", "none");
        }
      });
    });
  },
  methods: {
    getdata() {
      $("#example").DataTable().destroy();
      this.getkabkota();
    },
    geturl(kode_wilayah) {
      let routeData = this.$router.resolve({
        path: `/pm/detailpm/${kode_wilayah}`,
      });
      window.open(routeData.href, "_blank");
    },
    geturlppb(kode_wilayah) {
      let routeData = this.$router.resolve({
        path: `/pm/detailppb/${kode_wilayah}`,
      });
      window.open(routeData.href, "_blank");
    },
    getkabkota() {
      this.$loading(true);
      axios
        .get(this.url + `/kabkota_prov/` + this.user.id_ptsp + `/2024`, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.kabkota = response.data;
          this.initDatatable();
          this.$loading(false);
        })
        .catch((error) => {
          console.log(error);
          return error;
          this.$loading(false);
        });
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },

    getidkabkota(idkabkota) {
      this.idptsp_kabkota = idkabkota;
      localStorage.setItem("idptspkabkota", idkabkota);
      this.submitpersetujuan();
    },

    getidkab(idkabkot) {
      localStorage.setItem("idkab", idkabkot);
      this.$router.push({ name: "PM-PPKProvinsi" });
    },

    getidpenilaian(idkabkot) {
      localStorage.setItem("idptspkabkota", idkabkot);
      axios
        .get(
          this.url +
            `/penilaian_prov_pemda?filter=idprov,=,` +
            this.user.id_ptsp +
            `;idptsp,=,${idkabkot};tahun,=,2024`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.pm = response.data.data;
          this.idpenilaian = response.data.data.id;
          localStorage.setItem("idpenilaian", response.data.data[0].id);
          this.$router.push({ name: "PM-PPKProvinsi" });
        })
        .catch((error) => {
          console.log(error);
          return error;
          // this.loaddata()
        });
    },
    submitpersetujuan() {
      axios
        .post(
          this.url + "/penilaian_prov_pemda",
          {
            persetujuan: "Ya",
            alasan: this.alasan,
            idprov: this.user.id_ptsp,
            idptsp: this.idptsp_kabkota,
          },
          {
            headers: {
              "Content-type": "application/json",
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          this.idpenilaian = response.data.id;
          localStorage.setItem("idpenilaian", response.data.id);
          console.log(response);
          this.getkabkota();
          if (this.persetujuan === "Ya") {
            this.$router.push({ name: "PM-PPKProvinsi" });
          } else {
          }
          return response;
        })
        .catch((error) => {
          this.$bvToast.toast("Gagal Disimpan", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          console.log(error);
          return error;
        });
    },
    getkegiatan() {
      if (
        this.user.id_role === "ptsp_prov" ||
        this.user.id_role === "ptsp_provinsi"
      ) {
        axios
          .get(
            this.url +
              `/tanggal_kegiatans?filter=tanggal_mulai,<=,` +
              this.tanggal +
              `;tanggal_selesai,>=,` +
              this.tanggal,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.status = response.data.data;
          })
          .catch((error) => {
            return error;
          });
      } else {
        this.$router.push({ name: "dashboard" });
      }
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  created() {
    //   this.getuser(this.user)
    // this.getlocaldata()
  },
};
</script>
